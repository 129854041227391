.letter {
  font-size: 40px;
  font-family: sans-serif;
  fill: 	#0b0c0c;
  font-weight: bold;
}

.current-potential-number {
  font-size: 22px;
  font-family: sans-serif;
  fill: #000;
  line-height: 50px;
  margin-top: 100px;
}

.small-letter {
  font-size: 19px;
  font-family: sans-serif;
  fill: 	#0b0c0c;
  font-weight: bold;
  text-align: center;
}

.small {
  font-size: 20px;
  font-family: sans-serif;
  fill: 	#0b0c0c;
  line-height: 50px;
  margin-top: 100px;
  font-weight: bold;
}
  .band-placeholder{
    fill: #D2D2D2
  }

  .band-a{
    fill: #008054
  }
  .band-b{
    fill: #19b459
  }
  .band-c{
    fill: #8dce46
  }
  .band-d{
    fill: #ffd500
  }
  .band-e{
    fill: #fcaa65
  }
  .band-f{
    fill: #ef8023
  }
  .band-g{
    fill: #e9153b
  }
.band-a-score{
  fill: #67A687
}
.band-b-score{
  fill: #72CA8B
}
.band-c-score{
  fill: #b4df86
}
.band-d-score{
  fill: #ffe666
}
.band-e-score{
  fill: #fdc79b
}
.band-f-score{
  fill: #f4ac71
}
.band-g-score{
  fill: #f2738a
}