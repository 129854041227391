@tailwind base;
@tailwind components;
@tailwind utilities;

body, html, #root {
	height: 100%;
	min-height: 100vh;
	font-family: "Poppins", sans-serif;
}

@supports (-webkit-touch-callout: none) {
	body, html, #root {
		min-height: -webkit-fill-available !important; /*fixes iOS safari*/
	}
}

#root main {
	flex-grow: 1;
	width: 100%;
	display: inherit;
	flex-direction: inherit;
	flex-basis: 75vh;
}

@supports not (-webkit-touch-callout: none) {
	@supports (height: fit-content) and (min-height: min(1000px, 66vh)) {
		#root main {
			flex-basis: fit-content;
		}
		#root main > :first-of-type:only-child {
			flex-basis: min(1000px, 75vh);
		}
	}
}

#root {
	display: flex;
	flex-direction: column;
}

/* ensures sharpening of logo for better legibility */
.logo {
	max-width: none;
	image-rendering: crisp-edges;
	image-rendering: -moz-crisp-edges;          /* Firefox */
	image-rendering: -o-crisp-edges;            /* Opera */
	image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
	-ms-interpolation-mode: nearest-neighbor;   /* IE (non-standard property) */
}

button:focus, a:focus {
    box-shadow: none !important;
}

/* Storyblok content */
.section-blok h4 {
    color: #AAA
}

.section-blok h2,
.section-blok h3,
.section-blok h4, 
.section-blok p {
    margin-bottom: 1.5em !important;
}

.section-blok p img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    max-height: min(488px, 50vh);
}

/* Pipedrive chat widget */

/* Put behind cookie consent banner (z-index: 999) */
html body iframe#LeadboosterContainer {
	z-index: 998 !important;
}

/* Add background glow for footer visibility */
html body iframe#LeadboosterContainer.active {
	/* ff 3.6+ */
	background:-moz-radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 70%) !important;

	/* safari 5.1+,chrome 10+ */
	background:-webkit-radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 3.25) 0%, rgba(255, 255, 255, 0) 70%) !important;

	/* opera 11.10+ */
	background:-o-radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 70%) !important;

	/* ie 10+ */
	background:-ms-radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 70%) !important;

	/* global 92%+ browsers support */
	background:radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 70%) !important;
}